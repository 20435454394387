import Vue from 'vue'
import Router from 'vue-router'
import HomeView from './views/PageView.vue'

Vue.use(Router)

// this delay is needed to let
// my route transition by smooth
// wait for restoring til the new route
// is proper fade in
const restorationDelay = 400
const restorationDelay2 = 200

// Restores previous scroll position after popstate navigation
const scrollBehavior1 = ( to, from, savedPosition ) => {
	if( savedPosition ){
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve( savedPosition )
			}, restorationDelay)
		})
	}
	else{
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve( { x: 0, y: 0 } )
			}, restorationDelay2)
		})
		//return { x: 0, y: 100 }
	}
}
const scrollBehavior2 = ( to, from, savedPosition ) => {
	if( savedPosition ) {
		return savedPosition
	}
	else{
		return { x: 0, y: 0 }
	}
}

// Handle scrollRestoration manual, stops browser from doing it
if('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

// setup router + define routes
const router = new Router({
	base: '/',
	scrollBehavior: scrollBehavior1,
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			name: 'HomeView',
			component: HomeView
		},
		{
			path: "/404",
			name: 'NotFoundView--404',
			component: () =>
				import ( /* webpackChunkName: "NotFoundView" */ './views/NotFoundView.vue')		
		},
		{
			path: "/:slug", 
			name: 'PageView',
			component: () => 
				import ( /* webpackChunkName: "PageView" */ './views/PageView.vue')			
		},
		{
			path: "/referenzen/:slug", 
			name: 'ProjectView',
			component: () => 
				import ( /* webpackChunkName: "ProjectView" */ './views/ProjectView.vue')			
		},

		{
			path: "*", 
			name: 'NotFoundView',
			component: () =>
				import ( /* webpackChunkName: "NotFoundView" */ './views/NotFoundView.vue')			
		},
	]
})

// export router
export default router
