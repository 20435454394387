import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import VShowSlide from 'v-show-slide'
import VueMq from 'vue-mq'
import VueMeta from 'vue-meta'
import VueObserveVisibility from 'vue-observe-visibility'
import { EventBus } from '@/event-bus.js'
import appConfig from '@/../appConfig.js'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueObserveVisibility)
Vue.use(VueAxios, axios)
Vue.use(VueLodash)
Vue.use(VShowSlide)
Vue.use(VueMq, {
	breakpoints: {
		xs   : 320, // iPhone 5 / SE
		sm   : 650,	
		//md   : 1024,	// iPad 
		md   : 1150,	// iPad 
		dt   : 1500,
		lg   : 1920,
		//xl : 1920,
		xl   : Infinity,
	}
})

// Setup and mount root 
new Vue({
	router,
	store,
	data() {
		return {}
	},
	computed: {
		isSmallDevice() {
			//if( this.$mq === 'xs' || this.$mq === 'sm' ) return true
			return ( this.$mq === 'xs' || this.$mq === 'sm' ) ? true : false
		},
		isMqAboveMd() {
			//if( this.$mq === 'xs' || this.$mq === 'sm' ) return true
			return ( this.$mq === 'xl' || this.$mq === 'lg' || this.$mq === 'dt' ) ? true : false
		},
	},
	watch: {},
	methods: {
		getCurrentLang() {
			const validLangs = ['de', 'en']
			let currentLang = this.$route.params.lang
			
			if( !currentLang || validLangs.indexOf(currentLang) == -1 ){
				return 'de'				
			}else{
				return currentLang				
			}
		},
		getTranslatedAcfValue( parentObject, acfKey ) {			
			acfKey = acfKey + '__' + this.getCurrentLang() 

			if (parentObject && parentObject[acfKey]) {
				return parentObject[acfKey]
			} else {
				return null
			}

		},
		getTranslatedValue( deValue ){
			if(!this.$children[0].acfOptions) return deValue
			
			const currentLang = this.getCurrentLang()
			const translations = this.$children[0].acfOptions.translations2
			const enValue = translations[deValue]
			
			if('en' == currentLang && enValue){
				return enValue
			}else{
				return deValue				
			}
		},
		getPathFromUrl( url ){
			if( !url ) return 
			
			return url.replace(/^.*\/\/[^\/]+/, '')
		},
		trim( str, charlist ) {
			// taken from https://github.com/hirak/phpjs/blob/master/functions/strings/trim.js
			
			//  discuss at: http://phpjs.org/functions/trim/
			// original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// improved by: mdsjack (http://www.mdsjack.bo.it)
			// improved by: Alexander Ermolaev (http://snippets.dzone.com/user/AlexanderErmolaev)
			// improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// improved by: Steven Levithan (http://blog.stevenlevithan.com)
			// improved by: Jack
			//    input by: Erkekjetter
			//    input by: DxGx
			// bugfixed by: Onno Marsman
			//   example 1: trim('    Kevin van Zonneveld    ');
			//   returns 1: 'Kevin van Zonneveld'
			//   example 2: trim('Hello World', 'Hdle');
			//   returns 2: 'o Wor'
			//   example 3: trim(16, 1);
			//   returns 3: 6

			var whitespace, l = 0,
				i = 0;
			str += '';

			if (!charlist) {
				// default list
				whitespace =
					' \n\r\t\f\x0b\xa0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000';
			} else {
				// preg_quote custom list
				charlist += '';
				whitespace = charlist.replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '$1');
			}

			l = str.length;
			for (i = 0; i < l; i++) {
				if (whitespace.indexOf(str.charAt(i)) === -1) {
					str = str.substring(i);
					break;
				}
			}

			l = str.length;
			for (i = l - 1; i >= 0; i--) {
				if (whitespace.indexOf(str.charAt(i)) === -1) {
					str = str.substring(0, i + 1);
					break;
				}
			}

			return whitespace.indexOf(str.charAt(0)) === -1 ? str : '';
		}
	},
	created() {},
	mounted() {},
	render: h => h(App)
}).$mount('#app')
